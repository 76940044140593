* {
  margin: 0;
}

body {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  place-content: center;
  background-color: rgb(21, 0, 65);
}

p {
  font-size: xx-large;
  color: rgb(156, 209, 255);
}
